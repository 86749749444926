(function (wpm, $, undefined) {

	wpm.jQueryExists = async () => new Promise(resolve => {

		(function waitForjQuery() {
			if (typeof jQuery !== "undefined") return resolve()
			setTimeout(waitForjQuery, 100)
		})()
	})

	wpm.wpmDataLayerFullyLoaded = async () => {

		// Promise that checks if the nested object wpmDataLayer.shop is defined. If so, resolve
		let checkVar = new Promise((resolve) => {
			(function waitForVar() {
				if (
					typeof wpmDataLayer !== "undefined"
					&& typeof wpmDataLayer.version !== "undefined"
				) {
					return resolve()
				}
				setTimeout(waitForVar, 100)
			})()
		})

		// Set a timeout to log a message after 6 seconds
		let logTimeoutId = setTimeout(() => {
			console.error("Pixel Manager error: wpmDataLayer is not defined. Please make sure that the wpmDataLayer script snippet is inserted and not modified by any third-party plugin.")

		}, 6000);

		checkVar.then(() => {
			clearTimeout(logTimeoutId);
		});

		return checkVar;
	}

	wpm.wpHooksExists = async () => new Promise(resolve => {
		(function waitForVar() {

			// Resolve if wp.hooks is defined
			if (typeof wp !== "undefined" && typeof wp.hooks !== "undefined") return resolve()
			setTimeout(waitForVar, 50)
		})()
	})

	/**
	 * Load all WooCommerce hooks functions
	 *
	 * Includes safeguard in case the user changed the default load order for wp-hooks, or removed it completely.
	 *
	 * @returns {Promise<void>}
	 */
	wpm.loadWcHooksFunctions = async () => {
		// Load all WooCommerce Blocks hooks
		await wpm.wpHooksExists()
		require("./wc_hooks")
	}

}(window.wpm = window.wpm || {}, jQuery))
