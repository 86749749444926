/**
 * Create a wpm namespace under which all functions are declared
 */

// https://stackoverflow.com/a/5947280/4688612

(function (wpm, $, undefined) {

	const wpmDeduper = {
		keyName          : "_wpm_order_ids",
		cookieExpiresDays: 365,
	}

	const wpmRestSettings = {
		// cookiesAvailable                  : '_wpm_cookies_are_available',
		cookiePmwRestEndpointAvailable: "_pmw_endpoint_available",
		restEndpointPost              : "pmw/v1/test/",
		restFails                     : 0,
		restFailsThreshold            : 10,
	}

	wpm.emailSelected         = false
	wpm.paymentMethodSelected = false

	// wpm.checkIfCookiesAvailable = function () {
	//
	//     // read the cookie if previously set, if it is return true, otherwise continue
	//     if (wpm.getCookie(wpmRestSettings.cookiesAvailable)) {
	//         return true;
	//     }
	//
	//     // set the cookie for the session
	//     Cookies.set(wpmRestSettings.cookiesAvailable, true);
	//
	//     // read cookie, true if ok, false if not ok
	//     return !!wpm.getCookie(wpmRestSettings.cookiesAvailable);
	// }

	wpm.useRestEndpoint = () => {

		// only if sessionStorage is available

		// only if REST API endpoint is generally accessible
		// check in sessionStorage if we checked before and return answer
		// otherwise check if the endpoint is available, save answer in sessionStorage and return answer

		// only if not too many REST API errors happened

		return wpm.isSessionStorageAvailable() &&
			wpm.isRestEndpointAvailable() &&
			wpm.isBelowRestErrorThreshold()
	}

	wpm.isBelowRestErrorThreshold = () => window.sessionStorage.getItem(wpmRestSettings.restFails) <= wpmRestSettings.restFailsThreshold

	wpm.isRestEndpointAvailable = async () => {

		if (window.sessionStorage.getItem(wpmRestSettings.cookiePmwRestEndpointAvailable)) {
			return JSON.parse(window.sessionStorage.getItem(wpmRestSettings.cookiePmwRestEndpointAvailable))
		} else {
			return await wpm.testEndpoint()
		}
	}

	wpm.isSessionStorageAvailable = () => !!window.sessionStorage

	// Test the endpoint by sending a POST request
	wpm.testEndpoint = async (
		url        = wpm.root + wpmRestSettings.restEndpointPost,
		cookieName = wpmRestSettings.cookiePmwRestEndpointAvailable,
	) => {

		let response = await fetch(url, {
			method   : "POST",
			mode     : "cors",
			cache    : "no-cache",
			keepalive: true,
		})

		if (response.status === 200) {
			window.sessionStorage.setItem(cookieName, JSON.stringify(true))
			return true
		} else if (response.status === 404) {
			window.sessionStorage.setItem(cookieName, JSON.stringify(false))
			return false
		} else if (response.status === 0) {
			window.sessionStorage.setItem(cookieName, JSON.stringify(false))
			return false
		}
	}

	wpm.isWpmRestEndpointAvailable = (cookieName = wpmRestSettings.cookiePmwRestEndpointAvailable) => !!wpm.getCookie(cookieName)

	wpm.writeOrderIdToStorage = (orderId, orderKey, source = "thankyou_page", expireDays = 365) => {

		// save the order ID in the browser storage

		if (!window.Storage) {
			let expiresDate = new Date()
			expiresDate.setDate(expiresDate.getDate() + wpmDeduper.cookieExpiresDays)

			let ids = []
			if (checkCookie()) {
				ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName))
			}

			if (!ids.includes(orderId)) {
				ids.push(orderId)
				document.cookie = wpmDeduper.keyName + "=" + JSON.stringify(ids) + ";expires=" + expiresDate.toUTCString()
			}

		} else {
			if (localStorage.getItem(wpmDeduper.keyName) === null) {
				let ids = []
				ids.push(orderId)
				window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids))

			} else {
				let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName))
				if (!ids.includes(orderId)) {
					ids.push(orderId)
					window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids))
				}
			}
		}

		if (typeof wpm.storeOrderIdOnServer === "function" && wpmDataLayer.orderDeduplication) {
			wpm.storeOrderIdOnServer(orderKey, source)
		}
	}

	function checkCookie() {
		let key = wpm.getCookie(wpmDeduper.keyName)
		return key !== ""
	}

	wpm.isOrderIdStored = orderId => {

		if (wpmDataLayer.orderDeduplication) {

			if (!window.Storage) {

				if (checkCookie()) {
					let ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName))
					return ids.includes(orderId)
				} else {
					return false
				}
			} else {
				if (localStorage.getItem(wpmDeduper.keyName) !== null) {
					let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName))
					return ids.includes(orderId)
				} else {
					return false
				}
			}
		} else {
			console.log("order duplication prevention: off")
			return false
		}
	}

	/**
	 * Check if the email address is valid
	 *
	 * https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
	 * https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
	 * https://fightingforalostcause.net/content/misc/2006/compare-email-regex.php
	 *
	 * @param email
	 * @returns {boolean}
	 */
	wpm.isEmail = email => {

		/**
		 * GitHub Copilot generated RFC 5322 compliant regex
		 * - Don't allow emails without a top-level domain like "john@localhost"
		 * - Don't allow emails with dots at the end of the name like "john.doe.@example.com"
 		 */
		let regex = new RegExp(
			"^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))"
			+ "@"
			+ "((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])"
			+ "|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
		)

		return regex.test(email)
	}

	wpm.removeProductFromCart = async (productId, quantityToRemove = null) => {

		try {

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			let quantity

			if (quantityToRemove == null) {
				quantity = wpmDataLayer.cart[productId].quantity
			} else {
				quantity = quantityToRemove
			}

			if (!wpmDataLayer.cart[productId]) {
				await wpm.getProductsFromBackend([productId])
			}

			let product = wpm.getProductDetailsFormattedForEvent(productId, quantity)

			jQuery(document).trigger("wpmRemoveFromCart", product)

			if (quantityToRemove == null || wpmDataLayer.cart[productId].quantity === quantityToRemove) {

				delete wpmDataLayer.cart[productId]

				if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))
			} else {

				wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity - quantity

				if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))
			}
		} catch (e) {
			console.error(e)
			// console.log('getting cart from back end');
			// wpm.getCartItemsFromBackend();
			// console.log('getting cart from back end done');
		}
	}

	wpm.getIdBasedOndVariationsOutputSetting = productId => {

		try {
			if (wpmDataLayer?.general?.variationsOutput) return productId

			if (wpmDataLayer.products[productId].isVariation) return wpmDataLayer.products[productId].parentId

			return productId

		} catch (e) {
			console.error(e)
		}
	}

	// add_to_cart
	wpm.addProductToCart = async (productId, quantity) => {

		try {

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			if (!wpmDataLayer?.products[productId]) {
				await wpm.getProductsFromBackend([productId])
			}

			let product = wpm.getProductDetailsFormattedForEvent(productId, quantity)

			jQuery(document).trigger("wpmAddToCart", product)

			// add product to cart wpmDataLayer['cart']

			// if the product already exists in the object, only add the additional quantity
			// otherwise create that product object in the wpmDataLayer['cart']
			if (wpmDataLayer?.cart[productId]) {

				wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity + quantity
			} else {

				if (!("cart" in wpmDataLayer)) wpmDataLayer.cart = {}

				wpmDataLayer.cart[productId] = wpm.getProductDetailsFormattedForEvent(productId, quantity)
			}

			if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))

		} catch (e) {
			console.error(e)

			// fallback if wpmDataLayer.cart and wpmDataLayer.products got out of sync in case cart caching has an issue
			wpm.getCartItemsFromBackend()
		}
	}

	wpm.getCartItems = () => {

		if (sessionStorage) {
			if (!sessionStorage.getItem("wpmDataLayerCart") || wpmDataLayer.shop.page_type === "order_received_page") {
				sessionStorage.setItem("wpmDataLayerCart", JSON.stringify({}))
			} else {
				wpm.saveCartObjectToDataLayer(JSON.parse(sessionStorage.getItem("wpmDataLayerCart")))
			}
		} else {
			wpm.getCartItemsFromBackend()
		}
	}

	// get all cart items from the backend
	wpm.getCartItemsFromBackend = () => {
		try {

			/**
			 * Can't use a REST API endpoint, as the cart session will not be loaded if the
			 * endpoint is called.
			 *
			 * https://wordpress.org/support/topic/wc-cart-is-null-in-custom-rest-api/#post-11442843
			 */

			/**
			 * Get the cart items from the backend the data object using fetch API
			 * and log success or error messages
			 * and url encoded data
			 */
			fetch(wpm.ajax_url, {
				method   : "POST",
				cache    : "no-cache",
				body     : new URLSearchParams({action: "pmw_get_cart_items"}),
				keepalive: true,
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw Error("Error getting cart items from backend")
					}
				})
				.then(data => {

					if (data.success) {

						if (!data.data["cart"]) data.data["cart"] = {}

						wpm.saveCartObjectToDataLayer(data.data["cart"])

						if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(data.data["cart"]))

					} else {
						throw Error("Error getting cart items from backend")
					}
				})

		} catch (e) {
			console.error(e)
		}
	}

	// get productIds from the backend
	wpm.getProductsFromBackend = async productIds => {

		if (wpmDataLayer?.products) {
			// If productIds already exists as key in wpmDataLayer.products, remove it from the array
			productIds = productIds.filter(productId => !(productId in wpmDataLayer.products))
		}

		// if no products IDs are in the object, don't try to get anything from the server
		if (!productIds || productIds.length === 0) return

		try {

			let response

			if (await wpm.isRestEndpointAvailable()) {
				response = await fetch(wpm.root + "pmw/v1/products/", {
					method : "POST",
					cache  : "no-cache",
					headers: {
						"Content-Type": "application/json",
					},
					body   : JSON.stringify({
						pageId    : wpmDataLayer.general.pageId,
						pageType  : wpmDataLayer.shop.page_type,
						productIds: productIds,
					}),
				})
			} else {

				// Get the product details from the backend the data object using fetch API
				// and log success or error messages
				// and url encoded data
				response = await fetch(wpm.ajax_url, {
					method: "POST",
					cache : "no-cache",
					body  : new URLSearchParams({
						action    : "pmw_get_product_ids",
						pageId    : wpmDataLayer.general.pageId,
						pageType  : wpmDataLayer.shop.page_type,
						productIds: productIds,
					}),
				})
			}

			if (response.ok) {
				let responseData = await response.json()
				if (responseData.success) {
					wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, responseData.data)
				}
			} else {
				console.error("Error getting products from backend")
			}
		} catch (e) {
			console.error(e)
		}

		return true
	}

	wpm.saveCartObjectToDataLayer = cartObject => {

		wpmDataLayer.cart     = cartObject
		wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, cartObject)
	}

	wpm.triggerViewItemEventPrep = async productId => {

		if (wpmDataLayer.products && wpmDataLayer.products[productId]) {

			wpm.triggerViewItemEvent(productId)
		} else {
			await wpm.getProductsFromBackend([productId])
			wpm.triggerViewItemEvent(productId)
		}
	}

	wpm.triggerViewItemEvent = productId => {

		let product = wpm.getProductDetailsFormattedForEvent(productId)

		jQuery(document).trigger("wpmViewItem", product)
	}

	wpm.triggerViewItemEventNoProduct = () => {
		jQuery(document).trigger("wpmViewItem")
	}

	wpm.fireCheckoutOption = (step, checkout_option = null, value = null) => {

		let data = {
			step           : step,
			checkout_option: checkout_option,
			value          : value,
		}

		jQuery(document).trigger("wpmFireCheckoutOption", data)
	}

	wpm.fireCheckoutProgress = step => {

		let data = {
			step: step,
		}

		jQuery(document).trigger("wpmFireCheckoutProgress", data)
	}

	wpm.getPostIdFromString = string => {

		try {
			return string.match(/(post-)(\d+)/)[2]
		} catch (e) {
			console.error(e)
		}
	}

	wpm.triggerViewItemList = productId => {

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		jQuery(document).trigger("wpmViewItemList", wpm.getProductDataForViewItemEvent(productId))
	}

	wpm.getProductDataForViewItemEvent = productId => {

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		try {
			if (wpmDataLayer.products[productId]) {

				return wpm.getProductDetailsFormattedForEvent(productId)
			}
		} catch (e) {
			console.error(e)
		}
	}

	wpm.getMainProductIdFromProductPage = () => {

		try {
			if (["simple", "variable", "grouped", "composite", "bundle"].indexOf(wpmDataLayer.shop.product_type) >= 0) {
				return jQuery(".wpmProductId:first").data("id")
			} else {
				return false
			}
		} catch (e) {
			console.error(e)
		}
	}

	wpm.viewItemListTriggerTestMode = target => {

		jQuery(target).css({"position": "relative"})
		jQuery(target).append("<div id=\"viewItemListTriggerOverlay\"></div>")
		jQuery(target).find("#viewItemListTriggerOverlay").css({
			"z-index"         : "10",
			"display"         : "block",
			"position"        : "absolute",
			"height"          : "100%",
			"top"             : "0",
			"left"            : "0",
			"right"           : "0",
			"opacity"         : wpmDataLayer.viewItemListTrigger.opacity,
			"background-color": wpmDataLayer.viewItemListTrigger.backgroundColor,
		})
	}

	wpm.getSearchTermFromUrl = () => {

		try {
			let urlParameters = new URLSearchParams(window.location.search)
			return urlParameters.get("s")
		} catch (e) {
			console.error(e)
		}
	}

	// we need this to track timeouts for intersection observers
	let ioTimeouts = {}

	wpm.observerCallback = (entries, observer) => {

		entries.forEach((entry) => {

			try {
				let productId

				let elementId = jQuery(entry.target).data("ioid")

				// Get the productId from next element, if wpmProductId is a sibling, like in Gutenberg blocks
				// otherwise go search in children, like in regular WC loop items
				if (jQuery(entry.target).next(".wpmProductId").length) {
					// console.log('test 1');
					productId = jQuery(entry.target).next(".wpmProductId").data("id")
				} else {
					productId = jQuery(entry.target).find(".wpmProductId").data("id")
				}


				if (!productId) throw Error("wpmProductId element not found")

				if (entry.isIntersecting) {

					ioTimeouts[elementId] = setTimeout(() => {

						wpm.triggerViewItemList(productId)
						if (wpmDataLayer.viewItemListTrigger.testMode) wpm.viewItemListTriggerTestMode(entry.target)
						if (wpmDataLayer.viewItemListTrigger.repeat === false) observer.unobserve(entry.target)
					}, wpmDataLayer.viewItemListTrigger.timeout)

				} else {

					clearTimeout(ioTimeouts[elementId])
					if (wpmDataLayer.viewItemListTrigger.testMode) jQuery(entry.target).find("#viewItemListTriggerOverlay").remove()
				}
			} catch (e) {
				console.error(e)
			}
		})
	}

	// fire view_item_list only on products that have become visible
	let io
	let ioid = 0
	let allIoElementsToWatch

	let getAllElementsToWatch = () => {

		allIoElementsToWatch = jQuery(".wpmProductId")
			.map(function (i, elem) {

				if (
					jQuery(elem).parent().hasClass("type-product") ||
					jQuery(elem).parent().hasClass("product") ||
					jQuery(elem).parent().hasClass("product-item-inner")
				) {
					return jQuery(elem).parent()
				} else if (
					jQuery(elem).prev().hasClass("wc-block-grid__product") ||
					jQuery(elem).prev().hasClass("product") ||
					jQuery(elem).prev().hasClass("product-small") ||
					jQuery(elem).prev().hasClass("woocommerce-LoopProduct-link")
				) {
					return jQuery(this).prev()
				} else if (jQuery(elem).closest(".product").length) {
					return jQuery(elem).closest(".product")
				}
			})
	}

	wpm.startIntersectionObserverToWatch = () => {

		try {
			// enable view_item_list test mode from browser
			if (wpm.urlHasParameter("vildemomode")) wpmDataLayer.viewItemListTrigger.testMode = true

			// set up intersection observer
			io = new IntersectionObserver(wpm.observerCallback, {
				threshold: wpmDataLayer.viewItemListTrigger.threshold,
			})

			getAllElementsToWatch()

			allIoElementsToWatch.each((i, elem) => {

				jQuery(elem[0]).data("ioid", ioid++)

				io.observe(elem[0])
			})
		} catch (e) {
			console.error(e)
		}
	}

	// watch DOM for new lazy loaded products and add them to the intersection observer
	wpm.startProductsMutationObserverToWatch = () => {

		try {
			// Pass in the target node, as well as the observer options

			// selects the most common parent node
			// https://stackoverflow.com/a/7648323/4688612
			let productsNode = jQuery(".wpmProductId:eq(0)").parents().has(jQuery(".wpmProductId:eq(1)").parents()).first()

			if (productsNode.length) {
				productsMutationObserver.observe(productsNode[0], {
					attributes   : true,
					childList    : true,
					characterData: true,
				})
			}
		} catch (e) {
			console.error(e)
		}
	}

	// Create an observer instance
	let productsMutationObserver = new MutationObserver(mutations => {

		mutations.forEach(mutation => {
			let newNodes = mutation.addedNodes // DOM NodeList
			if (newNodes !== null) { // If there are new nodes added
				let nodes = jQuery(newNodes) // jQuery set
				nodes.each(function () {
					if (
						jQuery(this).hasClass("type-product") ||
						jQuery(this).hasClass("product-small") ||
						jQuery(this).hasClass("wc-block-grid__product")
					) {
						// check if the node has a child or sibling wpmProductId
						// if yes add it to the intersectionObserver
						if (hasWpmProductIdElement(this)) {
							jQuery(this).data("ioid", ioid++)
							io.observe(this)
						}
					}
				})
			}
		})
	})

	let hasWpmProductIdElement = elem =>
		!!(jQuery(elem).find(".wpmProductId").length ||
			jQuery(elem).siblings(".wpmProductId").length)

	wpm.setCookie = (cookieName, cookieValue = "", expiryDays = null) => {

		if (expiryDays) {

			let d = new Date()
			d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000))
			let expires     = "expires=" + d.toUTCString()
			document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"
		} else {
			document.cookie = cookieName + "=" + cookieValue + ";path=/"
		}
	}

	wpm.getCookie = cookieName => {

		let name          = cookieName + "="
		let decodedCookie = decodeURIComponent(document.cookie)
		let ca            = decodedCookie.split(";")

		for (let i = 0; i < ca.length; i++) {

			let c = ca[i]

			while (c.charAt(0) == " ") {
				c = c.substring(1)
			}

			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length)
			}
		}

		return ""
	}

	wpm.deleteCookie = cookieName => {
		wpm.setCookie(cookieName, "", -1)
	}

	wpm.getWpmSessionData = () => {

		if (window.sessionStorage) {

			let data = window.sessionStorage.getItem("_wpm")

			if (data !== null) {
				return JSON.parse(data)
			} else {
				return {}
			}
		} else {
			return {}
		}
	}

	wpm.setWpmSessionData = data => {
		if (window.sessionStorage) {
			window.sessionStorage.setItem("_wpm", JSON.stringify(data))
		}
	}

	wpm.storeOrderIdOnServer = async (orderKey, source) => {

		try {

			let response

			if (await wpm.isRestEndpointAvailable()) {

				response = await fetch(wpm.root + "pmw/v1/pixels-fired/", {
					method   : "POST",
					headers  : {
						"Content-Type": "application/json",
						"X-WP-Nonce"  : wpm.nonce_wp_rest,
					},
					body     : JSON.stringify({
						// order_id: orderId,
						order_key: orderKey,
						source   : source,
						// nonce   : wpm.pmw_nonce,
					}),
					keepalive: true,
					cache    : "no-cache",
				})

			} else {
				// save the state in the database

				// Send the data object with ajax request
				// and log success or error using fetch API and url encoded
				response = await fetch(wpm.ajax_url, {
					method   : "POST",
					body     : new URLSearchParams({
						action: "pmw_purchase_pixels_fired",
						// order_id: orderId,
						order_key : orderKey,
						source    : source,
						nonce_ajax: wpm.nonce_ajax,
					}),
					keepalive: true,
				})
			}

			const responseJson = await response.json()

			if (responseJson.success) {
				console.log("wpm.storeOrderIdOnServer success")
			} else {
				console.error("wpm.storeOrderIdOnServer error", responseJson)
			}

		} catch (e) {
			console.error(e)
		}
	}

	wpm.getProductIdByCartItemKeyUrl = url => {

		let searchParams = new URLSearchParams(url.search)
		let cartItemKey  = searchParams.get("remove_item")

		let productId

		if (wpmDataLayer.cartItemKeys[cartItemKey]["variation_id"] === 0) {
			productId = wpmDataLayer.cartItemKeys[cartItemKey]["product_id"]
		} else {
			productId = wpmDataLayer.cartItemKeys[cartItemKey]["variation_id"]
		}

		return productId
	}

	wpm.getAddToCartLinkProductIds = () =>
		jQuery("a").map(function () {
			let href = jQuery(this).attr("href")

			if (href && href.includes("?add-to-cart=")) {
				let matches = href.match(/(add-to-cart=)(\d+)/)
				if (matches) return matches[2]
			}
		}).get()

	wpm.getProductDetailsFormattedForEvent = (productId, quantity = 1) => {

		let product = {
			id           : productId.toString(),
			dyn_r_ids    : wpmDataLayer.products[productId].dyn_r_ids,
			name         : wpmDataLayer.products[productId].name,
			list_name    : wpmDataLayer.shop.list_name,
			brand        : wpmDataLayer.products[productId].brand,
			category     : wpmDataLayer.products[productId].category,
			variant      : wpmDataLayer.products[productId].variant,
			list_position: wpmDataLayer.products[productId].position,
			quantity     : quantity,
			price        : wpmDataLayer.products[productId].price,
			currency     : wpmDataLayer.shop.currency,
			isVariable   : wpmDataLayer.products[productId].isVariable,
			isVariation  : wpmDataLayer.products[productId].isVariation,
			parentId     : wpmDataLayer.products[productId].parentId,
		}

		if (product.isVariation) product["parentId_dyn_r_ids"] = wpmDataLayer.products[productId].parentId_dyn_r_ids

		return product
	}

	wpm.setReferrerToCookie = () => {

		// can't use session storage as we can't read it from the server
		if (!wpm.getCookie("wpmReferrer")) {
			wpm.setCookie("wpmReferrer", document.referrer)
		}
	}

	wpm.getReferrerFromCookie = () => {

		if (wpm.getCookie("wpmReferrer")) {
			return wpm.getCookie("wpmReferrer")
		} else {
			return null
		}
	}

	wpm.getClidFromBrowser = (clidId = "gclid") => {

		let clidCookieId

		clidCookieId = {
			gclid: "_gcl_aw",
			dclid: "_gcl_dc",
		}

		if (wpm.getCookie(clidCookieId[clidId])) {

			let clidCookie = wpm.getCookie(clidCookieId[clidId])
			let matches    = clidCookie.match(/(GCL.[\d]*.)(.*)/)
			return matches[2]
		} else {
			return ""
		}
	}

	wpm.getUserAgent = () => navigator.userAgent

	wpm.getViewPort = () => ({
		width : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
		height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
	})


	wpm.version = () => {
		console.log(wpmDataLayer.version)
	}

	/**
	 * https://api.jquery.com/jquery.getscript/
	 *
	 * Switched back to jQuery.ajax as the fetch method on some sites returned a type error
	 * Possible reasons are:
	 *    * CORS mismatch
	 *    * The user is using an ad blocker
	 */

	wpm.loadScriptAndCacheIt = url => {

		// Get and load the script using fetch API, if possible from cache, and return it without using eval
		// return fetch(url, {
		// 	method   : "GET",
		// 	cache    : "default",
		// 	keepalive: true,
		// })
		// 	.then(response => {
		// 		if (response.ok) {
		// 			// console.log("response", response)
		// 			return response.text()
		// 			// console.log("wpm.loadScriptAndCacheIt success: " + url)
		// 		} else {
		// 			throw new Error("Network response was not ok: " + url)
		// 		}
		// 	})
		// 	.then(script => {
		// 		// Execute the script
		// 		// console.error("executing script: " + script)
		// 		eval(script)
		// 		// console.log("executed script: " + script)
		// 	})
		// 	.catch(e => {
		// 		console.error(e)
		// 	})

		let options = {
			dataType: "script",
			cache   : true,
			url     : url,
		}

		return jQuery.ajax(options)
	}

	wpm.getOrderItemPrice = orderItem => (orderItem.total + orderItem.total_tax) / orderItem.quantity

	wpm.hasLoginEventFired = () => {
		let data = wpm.getWpmSessionData()
		return data?.loginEventFired
	}

	wpm.setLoginEventFired = () => {
		let data                = wpm.getWpmSessionData()
		data["loginEventFired"] = true
		wpm.setWpmSessionData(data)
	}

	wpm.pageLoaded = async () => new Promise(resolve => {
		(function waitForVar() {
			if ("complete" === document.readyState) return resolve()
			setTimeout(waitForVar, 50)
		})()
	})

	wpm.pageReady = () => {
		return new Promise(resolve => {
			(function waitForVar() {
				if ("interactive" === document.readyState || "complete" === document.readyState) return resolve()
				setTimeout(waitForVar, 50)
			})()
		})
	}

	wpm.isMiniCartActive = () => {
		if (window.sessionStorage) {
			Object.keys(window.sessionStorage).forEach(key => {
				if (key.includes("wc_fragments")) {
					return true
				}
			})
			return false
		} else {
			return false
		}
	}

	wpm.doesWooCommerceCartExist = () => document.cookie.includes("woocommerce_items_in_cart")

	wpm.urlHasParameter = parameter => {
		let urlParams = new URLSearchParams(window.location.search)
		return urlParams.has(parameter)
	}

	wpm.getUrlParameter = parameter => {
		let urlParams = new URLSearchParams(window.location.search)
		return urlParams.get(parameter)
	}

	// https://stackoverflow.com/a/60606893/4688612
	wpm.hashAsync = (algo, str) => {
		return crypto.subtle.digest(algo, new TextEncoder("utf-8").encode(str)).then(buf => {
			return Array.prototype.map.call(new Uint8Array(buf), x => (("00" + x.toString(16)).slice(-2))).join("")
		})
	}

	wpm.getCartValue = () => {

		let value = 0

		if (wpmDataLayer?.cart) {

			for (const key in wpmDataLayer.cart) {
				// content_ids.push(wpmDataLayer.products[key].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type])

				let product = wpmDataLayer.cart[key]

				value += product.quantity * product.price
			}
		}

		return value
	}

	/**
	 * Detect if the current URL contains at least one pattern
	 *
	 * @param patterns
	 * @returns {boolean}
	 */
	wpm.doesUrlContainPatterns = patterns => {

		for (const pattern of patterns) {
			if (new RegExp(pattern).test(window.location.href)) {
				return true
			}
		}

		return false
	}

	/**
	 * Detect if the current URL contains at least one pattern that is on the tracking exclusion list
	 *
	 * https://www.linkedin.com/pulse/how-remove-google-robot-problem-via-gtm-facebook-pixel-hjelpdahl/
	 * https://www.youtube.com/watch?v=b4I1ePZt8Z0
	 *
	 * @returns {boolean}
	 */
	wpm.excludeDomainFromTracking = () => {

		let excludeDomains = [
			"appspot.com",
			"translate.google.com",
		]

		if (wpmDataLayer?.general?.excludeDomains) {
			excludeDomains = [...excludeDomains, ...wpmDataLayer.general.excludeDomains]
		}

		// Abort if URL contains excluded domains
		if (excludeDomains.some(domain => window.location.href.includes(domain))) {
			console.debug("Pixel Manager for WooCommerce: Aborted due to excluded domain")
			return true
		}

		return false
	}

	wpm.getRandomEventId = () => (Math.random() + 1).toString(36).substring(2)

	wpm.pmwConsoleMessage = () => {
		let message = "Pixel Manager for WooCommerce: "
		message += wpmDataLayer.version.pro ? "pro" : "free"
		message += " | distro: " + wpmDataLayer.version.distro
		if (wpmDataLayer.version.distro === "fms" && wpmDataLayer.version.pro) {
			message += " | active license: " + (wpmDataLayer.version.eligibleForUpdates ? "yes" : "no")
		}
		message += " | version: " + wpmDataLayer.version.number

		console.log(message)
	}

	wpm.canLoadPremiumFeatures = () => {
		return (wpmDataLayer.version.distro === "fms" && wpmDataLayer.version.pro && wpmDataLayer.version.eligibleForUpdates) || wpmDataLayer.version.distro === "wcm"
	}

	let jQueryReadyFired = false

	wpm.triggerDomReadyEvent = () => {
		if (jQueryReadyFired === false) jQuery(document).trigger("pmw:ready")
		jQueryReadyFired = true
	}

	jQuery(document).on("ready", () => {
		wpm.triggerDomReadyEvent()
	})

	document.addEventListener("DOMContentLoaded", () => {
		wpm.triggerDomReadyEvent()
	})

	wpm.getEmailFromTarget = target => {

		// Only try to add the clicked email if href is set
		if(target.href)	{

			// Get the email from the link
			// But only if there is a valid email address in the link
			// Also, the href may contain other parameters, so we need to check for that and remove them
			let email = target.href.replace("mailto:", "")


			if (email.indexOf("?") > -1) {
				email = email.split("?")[0]
			}

			// Trim the email and remove all whitespaces
			email = email.replace(/\s/g, "")

			// If the email is not empty and valid, add it to the data object
			if (email && wpm.isEmail(email)) {
				return email
			}
		}

		return ""
	}


}(window.wpm = window.wpm || {}, jQuery))
